// src/components/HomeContent.jsx
import React from 'react';
import { Twitter, Instagram, Rss } from 'lucide-react';
import { HIGHLIGHT_COLOR, SECONDARY_FONT } from '../constants';

import {XsIcon} from '../icons/x.svg';

const HomeContent = () => {
  return (
    <div className=" max-w-3xl mx-auto text-white">
      <div className="w-11/12 mx-auto backdrop-blur bg-gray-950 bg-opacity-75 p-4 mb-8 rounded-md">
        <h2 className="text-3xl font-bold mb-1 tracking-tighter text-center text-sky-200" style={{ fontFamily: SECONDARY_FONT }}>
        Beatmaker, singer and Mexican
        </h2>
        <h3 className="font-black text-center text-md tracking-tighter ">Reggaeton // lo-fi // dancehall // pop</h3>
      </div>
      <div className="max-w-72 mx-auto backdrop-blur bg-gray-950 bg-opacity-75 p-4 mb-8 rounded-md">
      <p className="text-center text-md">🏝️ Listen Tropical music on:</p>
        <a href="https://manifiesto.rickyzafiro.com" target="_blank" rel="noopener noreferrer">
          <h3 className="font-black text-center text-md tracking-tighter">
            Manifiesto Sonidero
          </h3>
        </a>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="w-11/12 max-w-96 mx-auto backdrop-blur bg-gray-950 bg-opacity-75 p-6 rounded-md justify-center">
          <h3 className="text-2xl font-bold mb-4 tracking-tighter text-center" style={{ fontFamily: SECONDARY_FONT }}>
            Latest Release
          </h3>
          <div className="flex aspect-w-16 aspect-h-9 mb-4 justify-center">
            <iframe
              src="https://www.youtube.com/embed/UeUowNEEPJ0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="rounded-2xl"
            ></iframe>
          </div>
          <p>
          "Flaquita," is a perfect fusion of reggaeton and lo-fi. My main goal is to evoke nostalgia. Enjoy it!
          </p>
        </div>
        <div className="w-11/12 max-w-96 mx-auto backdrop-blur bg-gray-950 bg-opacity-75 p-6 rounded-md">
          <h3 className="text-2xl font-bold mb-4 tracking-tighters text-center" style={{ fontFamily: SECONDARY_FONT }}>
            Socials
          </h3>
          <div className="flex space-x-4 mb-6 justify-center">
          <a href="#" className={`text-white hover:text-sky-300 transition-colors duration-300`}>
            <Rss size={24} />
          </a>
          <a href="#" className={`text-white hover:text-sky-300 transition-colors duration-300`}>
            <Twitter size={24} />
          </a>
          <a href="#" className={`text-white hover:text-sky-300 transition-colors duration-300`}>
            <Instagram size={24} />
          </a>
        </div>
          <blockquote className={`border-l-4 border-sky-200 pl-4 italic mb-6`} style={{ fontFamily: SECONDARY_FONT }}>
          "Si mi música llega a inspirar a algunos, valdrá la pena el tiempo y esfuerzo invertido".
        </blockquote>
        </div>
        <div className="h-12"></div>
      </div>
    </div>
  );
};

export default HomeContent;
