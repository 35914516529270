// src/components/Contact.jsx
import React, { useState } from 'react';
import { HIGHLIGHT_COLOR, SECONDARY_FONT } from '../constants';

const Contact = () => {
  const [formStatus, setFormStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      const response = await fetch('https://formspree.io/f/contact@fologan.com', {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.ok) {
        setFormStatus('¡Gracias por tu mensaje! Te responderemos pronto.');
        e.target.reset();
      } else {
        setFormStatus('Hubo un problema al enviar el mensaje. Por favor, inténtalo de nuevo.');
      }
    } catch (error) {
      setFormStatus('Hubo un error al enviar el mensaje. Por favor, inténtalo de nuevo más tarde.');
    }
  };

  return (
    <div className="max-w-96 mx-auto text-white backdrop-blur-md bg-black bg-opacity-50 p-6 rounded-lg">
      <h2 className="text-3xl font-bold mb-4 tracking-tighter" style={{ fontFamily: SECONDARY_FONT }}>
        Contact Ricky Zafiro
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input type="text" name="name" placeholder="Your Name" required className="w-full p-2 rounded text-black" />
        <input type="email" name="email" placeholder="Your Email" required className="w-full p-2 rounded text-black" />
        <textarea name="message" placeholder="Your Message" required className="w-full p-2 rounded text-black" rows={4}></textarea>
        <button type="submit" className={`bg-neutral-950 text-white px-4 py-2 rounded hover:bg-opacity-80`}>
          Send
        </button>
      </form>
      {formStatus && <p className="mt-4 text-center">{formStatus}</p>}
    </div>
  );
};

export default Contact;
