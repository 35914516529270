// src/components/Header.jsx
import React from 'react';
import { SECONDARY_FONT } from '../constants';

const Header = () => {
  return (
    <header className="relative z-20">
      <div className="h-24 bg-gradient-to-b from-gray-950 to-transparent">
        <h1 className="text-6xl lg:text-7xl lg:py-3 font-bold text-white text-center py-5 relative z-20 tracking-tighter" style={{ fontFamily: SECONDARY_FONT }}>
          Ricky Zafiro
        </h1>
      </div>
    </header>
  );
};

export default Header;
