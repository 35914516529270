// src/components/BlogEntry.jsx
import React from 'react';
import { HIGHLIGHT_COLOR, SECONDARY_FONT } from '../constants';
import ReactMarkdown from 'react-markdown';

const BlogEntry = ({ entry, isExpanded, toggleExpand }) => {
  return (
    <div className="max-w-96 mx-auto backdrop-blur-md bg-black bg-opacity-50 p-6 rounded-md">
      <div className="flex items-center mb-2">
        {entry.tags.map(tag => (
          <span key={tag} className={`text-xs bg-neutral-900 px-2 py-1 rounded-md mr-2`}>
            {tag}
          </span>
        ))}
      </div>
      <h3 className="text-2xl font-bold mb-2 tracking-tighter" style={{ fontFamily: SECONDARY_FONT }}>
        {entry.title}
      </h3>
      <ReactMarkdown className="mb-4">
        {entry.content}
      </ReactMarkdown>
      {entry.spotify && (
        <div className="mb-4">
          <iframe 
            style={{borderRadius: '12px'}} 
            src={entry.spotify} 
            width="100%" 
            height="152" 
            frameBorder="0" 
            allowFullScreen="" 
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
            loading="lazy"
          ></iframe>
        </div>
      )}
      <button 
        onClick={toggleExpand}
        className={`bg-neutral-900 p-2 rounded-md text-xs hover:bg-sky-950 transition duration-500 ease-in-out `}
      >
        {isExpanded ? 'See less' : 'See more'}
      </button>
      {isExpanded && (
        <div className="mt-4">
          <p className="text-sm font-bold">date: {entry.date}</p>
        </div>
      )}
    </div>
  );
};

export default BlogEntry;
