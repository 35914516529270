// src/components/Footer.jsx
import React from 'react';
import { Home, Library, Gem, Mail } from 'lucide-react';

const Footer = ({ activePage, setActivePage }) => {
  const navItems = [
    { id: 'home', icon: <Home />, label: 'Home' },
    { id: 'albums', icon: <Library />, label: 'Albums' },
    { id: 'about', icon: <Gem />, label: 'About' },
    { id: 'contact', icon: <Mail />, label: 'Contact' },
  ];

  return (
    <footer className="fixed bottom-0 left-0 right-0 flex justify-center w-full backdrop-blur bg-gray-950 bg-opacity-75 text-white py-4 relative z-20">
      <nav className="max-w-xl w-full flex justify-around">
        {navItems.map(item => (
          <button
            key={item.id}
            onClick={() => setActivePage(item.id)}
            className={`flex flex-col items-center ${activePage === item.id ? `text-sky-300` : ''}`}
          >
            {item.icon}
            <span>{item.label}</span>
          </button>
        ))}
      </nav>
    </footer>
  );
};

export default Footer;
