// src/components/About.jsx
import React from 'react';
import { SECONDARY_FONT } from '../constants';

const About = () => {
  return (
    <div className="max-w-96 mx-auto text-white backdrop-blur bg-gray-950 bg-opacity-75 p-6 rounded-lg p-12">
      <h2 className="text-3xl font-bold mb-4 tracking-tighter" style={{ fontFamily: SECONDARY_FONT }}>
        About Ricky Zafiro
      </h2>
      <div className="space-y-4">
      <p className="mb-9 pl-3">
        I'm Ricky Zafiro, a YouTuber, beatmaker, jazz lover, graphic designer, gamer, and I make filters for TikTok 🤪. Someday we're going to leave this world, so why not do what we love? Alongside my solo work, I’m part of 
        <a href="https://manifiesto.rickyzafiro.com" target="_blank" rel="noopener noreferrer" className="text-sky-300 font-black"> Manifiesto Sonidero</a>, where we bring a fresh vibe to cumbia and grupera music. I write and produce my songs from my home studio, and I'm diving into the world of piano as well.
      </p>
        <h3 className="font-bold">Biography</h3>
        <div className="pl-3">
        <p className="mb-6">I was born in Xalapa, Veracruz, and I became interested in music when my dad used to leave his Yamaha PSR keyboard at home. I would secretly use it to explore the special effects and rhythms.</p>
        <p className="mb-6">In middle school, along with a group of friends, we dedicated ourselves to making hip-hop. Back then, I was in charge of making beats on FL Studio for my rapper friends from the neighborhood, who spoke about things happening on the streets where we lived, and where some people gathered at parties called "cotos" to listen to the songs. I also made some music myself, but my true passion was the beats.</p>
        <p className="mb-6">Later, as a teenager, I joined a Catholic church choir, where I finally got to learn some real music. That's where I first learned to play the piano. I wasn’t playing at a high level, but it was enough to be part of a choir with a rock style. I served the church choir for seven years, which taught me a lot about music and how to convey emotions to people.</p>
        <p className="mb-6">I was also part of a Latin rock band called "The Chatos," where we played ska music, some Caifanes, Maná, and others, and by then, music had become everything for me.</p>
        <p className="mb-6">During a crucial stage of my life, an old friend encouraged me to sing karaoke, and I discovered a side of myself that I didn’t know. It allowed me to open up little by little. I was surprised by how people enjoyed my singing and even cheered for me. Not many know this, but that’s where I learned to sing.</p>
        <p className="mb-6">Due to my attention deficit, it’s hard for me to focus on a single project, and I can’t give 100% of my time to just one. Each new emotion that comes up motivates me to create new projects, like Manifiesto Sonidero, where my main purpose is to create tropical music in honor of my grandfather Rogelio and my dad, who passed on their love for the music of our roots, the music of Veracruz, the cumbia. I hope to complete that project as well, and thank you for reading.</p>
        <p className="mb-6">By the way, the name Ricky Zafiro comes from my grandmother's name, "Ricarda," whose masculine form in English is Ricky. I chose 'Zafiro' because I used to have an alter ego named Charles Diamond, but I wanted this new alter ego to be something beyond a diamond. While talking with ChatGPT, I discovered that the sapphire is better than the diamond, and, besides, it was the gem I enjoyed searching for the most in Zelda BOTW.</p>
        </div>
      </div>
    </div>
  );
};

export default About;
