// src/hooks/useBlogEntries.js
import { useState, useEffect } from 'react';
import matter from 'gray-matter';

const useBlogEntries = () => {
  const [entries, setEntries] = useState([]);
  const [error, setError] = useState(null); // Estado para manejar errores

  useEffect(() => {
    const fetchBlogEntries = async () => {
      try {
        console.log('Fetching index.json...');
        const response = await fetch('/blog/index.json');
        if (!response.ok) {
          throw new Error(`Error fetching index.json: ${response.statusText}`);
        }
        const fileNames = await response.json();
        console.log('File names fetched:', fileNames);

        const fetchedEntries = await Promise.all(
          fileNames.map(async (fileName) => {
            console.log(`Fetching ${fileName}...`);
            const res = await fetch(`/blog/${fileName}`);
            if (!res.ok) {
              throw new Error(`Error fetching ${fileName}: ${res.statusText}`);
            }
            const text = await res.text();
            const { data, content } = matter(text);
            console.log(`Parsed ${fileName}:`, data);
            return {
              id: fileName,
              title: data.title,
              content: content,
              date: data.date,
              tags: data.tags,
              spotify: data.spotify || null,
            };
          })
        );

        setEntries(fetchedEntries);
        console.log('All entries fetched successfully:', fetchedEntries);
      } catch (err) {
        console.error('Error fetching blog entries:', err);
        setError(err.message);
      }
    };

    fetchBlogEntries();
  }, []);

  if (error) {
    console.error('useBlogEntries encountered an error:', error);
  }

  return entries;
};

export default useBlogEntries;
