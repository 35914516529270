// src/components/AlbumsContent.jsx
import React, { useState, useEffect } from 'react';
import { Music, Popcorn, Book, Calendar } from 'lucide-react';
import BlogEntry from './BlogEntry';
import useBlogEntries from '../hooks/useBlogEntries.js';
import { HIGHLIGHT_COLOR, SECONDARY_FONT } from '../constants';

const AlbumsContent = ({ activeTag, setActiveTag }) => {
  const [expandedEntry, setExpandedEntry] = useState(null);
  const blogEntries = useBlogEntries();

  // Filtrar entradas según la etiqueta activa
  const filteredEntries = activeTag === 'all' 
    ? blogEntries 
    : blogEntries.filter(entry => entry.tags.includes(activeTag));

  // Ordenar las entradas: las que tienen 'music' primero, luego por fecha descendente
  const sortedEntries = [...filteredEntries].sort((a, b) => {
    if (activeTag === 'all') {
      if (a.tags.includes('music') && !b.tags.includes('music')) return -1;
      if (!a.tags.includes('music') && b.tags.includes('music')) return 1;
    }
    return new Date(b.date) - new Date(a.date);
  });

  const tags = ['all', 'music', 'read', 'news'];

  return (
    <div className="text-white">
      <h2 className="text-3xl font-bold mb-4 tracking-tighter" style={{ fontFamily: SECONDARY_FONT }}>
        Latest Releases and News
      </h2>
      <div className="mb-4 flex space-x-2">
        {tags.map(tag => (
          <button
            key={tag}
            onClick={() => setActiveTag(tag)}
            className={`px-3 py-1 rounded-lg flex font-bold items-center ${activeTag === tag ? `bg-sky-400 text-sky-950` : 'bg-neutral-900 text-zinc-400'}`}
          >
            {tag !== 'all' && (() => {
              switch(tag) {
                case 'music': return <Music className="mr-1" size={21} />;
                case 'read': return <Book className="mr-1" size={21} />;
                case 'news': return <Popcorn className="mr-1" size={21} />;
                default: return null;
              }
            })()}
            {tag.charAt(0).toUpperCase() + tag.slice(1)}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {sortedEntries.map(entry => (
          <BlogEntry
            key={entry.id}
            entry={entry}
            isExpanded={expandedEntry === entry.id}
            toggleExpand={() => setExpandedEntry(expandedEntry === entry.id ? null : entry.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default AlbumsContent;
