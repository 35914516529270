import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeContent from './components/HomeContent';
import AlbumsContent from './components/AlbumsContent';
import About from './components/About';
import Contact from './components/Contact';
import { HIGHLIGHT_COLOR, PRIMARY_FONT } from './constants';
import './styles/GradientBackground.css';

const backgroundImages = {
  home: 'https://raw.githubusercontent.com/Fologan/fenotipo/main/flores.jpeg',
  albums: 'https://raw.githubusercontent.com/Fologan/fenotipo/main/casco.jpeg',
  about: 'https://raw.githubusercontent.com/Fologan/fenotipo/main/leopardo.jpeg',
  contact: 'https://raw.githubusercontent.com/Fologan/fenotipo/main/cocodrilo.jpeg',
};

const App = () => {
  const [activePage, setActivePage] = useState('home');
  const [activeTag, setActiveTag] = useState('all');

  useEffect(() => {
    document.body.style.margin = '0';
    document.body.style.padding = '0';
    document.body.style.height = '100vh';
    document.body.style.overflow = 'hidden';
    document.body.style.fontFamily = PRIMARY_FONT;
  }, []);

  const renderContent = () => {
    switch(activePage) {
      case 'home':
        return <HomeContent />;
      case 'albums':
        return <AlbumsContent activeTag={activeTag} setActiveTag={setActiveTag} />;
      case 'about':
        return <About />;
      case 'contact':
        return <Contact />;
      default:
        return <HomeContent />;
    }
  };

  return (
    <div className="flex flex-col h-screen relative">
      {activePage === 'home' ? (
        <div className="gradient-bg fixed inset-0 bg-cover bg-center z-0">
          <svg viewBox="0 0 100vw 100vw" xmlns="http://www.w3.org/2000/svg" className="noiseBg">
            <filter id="noiseFilterBg">
              <feTurbulence type="fractalNoise" baseFrequency="0.6" stitchTiles="stitch" />
            </filter>
            <rect width="100%" height="100%" preserveAspectRatio="xMidYMid meet" filter="url(#noiseFilterBg)" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" className="svgBlur">
            <defs>
              <filter id="goo">
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8" result="goo" />
                <feBlend in="SourceGraphic" in2="goo" />
              </filter>
            </defs>
          </svg>
          <div className="gradients-container">
            <div className="g1"></div>
            <div className="g2"></div>
            <div className="g3"></div>
            <div className="g4"></div>
            <div className="g5"></div>
            <div className="interactive"></div>
          </div>
        </div>
      ) : (
        <div 
          className="fixed inset-0 bg-cover bg-center z-0"
          style={{ backgroundImage: `url(${backgroundImages[activePage]})` }}
        />
      )}
      
      <Header className="relative z-10" />

      <AnimatePresence mode="wait">
        <motion.main
          key={activePage}
          className="flex-grow overflow-auto relative z-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {activePage === 'home' ? (
            renderContent()
          ) : (
            <div className="p-10">
              {renderContent()}
            </div>
          )}
        </motion.main>
      </AnimatePresence>

      <Footer activePage={activePage} setActivePage={setActivePage} className="relative z-10" />
    </div>
  );
};

export default App;